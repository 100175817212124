<template>
<div class="centerRight">
  <div class="b">
    <dv-border-box-12>
      <div class="box">
        <div class="head">
          <span class="blue">公司阶段及行业领域分布</span>
          <dv-decoration-2 style="width:100%;height:.1rem;"></dv-decoration-2>
        </div>
        <mSwiper  height='3rem' noDrag>
          <swiper-slide class="swiper-slide" >
            <centreRightChart1></centreRightChart1>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <centreRightChart2></centreRightChart2>
          </swiper-slide>

        </mSwiper>
      </div>

    </dv-border-box-12>

  </div>

</div>
</template>

<script>
import mSwiper from "@/components/mSwiper";
import centreRightChart1 from "@/components/echart/centreRight/centreRightChart1";
import centreRightChart2 from "@/components/echart/centreRight/centreRightChart2";
export default {
  components: {
    mSwiper,
    centreRightChart1,
    centreRightChart2
  },
  data() {
    return {
      data: [{
          name: '周口',
          value: 55
        },
        {
          name: '南阳',
          value: 120
        },
        {
          name: '西峡',
          value: 78
        },
        {
          name: '驻马店',
          value: 66
        },
        {
          name: '新乡',
          value: 80
        },
        {
          name: '信阳',
          value: 45
        },
        {
          name: '漯河',
          value: 29
        }
      ]
    };
  },
  computed: {
    config() {
      return {
        waitTime:3000,
        data: this.data
      }
    }
  },
  mounted() {},
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.centerRight {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    margin-bottom: 1.5rem;
    .b {
        flex: 1;
        .box {
            padding: 0.25rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            .c {
                flex: 1;
                position: relative;
                > div {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .head {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-bottom: 0.1rem;
        .blue {
            color: #5cb3ff;
            font-size: 0.2rem;
            margin-bottom: 0.1rem;
        }
    }


}
</style>
