<template lang="html">
  <div class="swiperBox " :class="{'swiper-no-swiping':noDrag}" :style="{'width':width,'height':height}">

  <swiper :options="swiperOption">
    <slot>

    </slot>
  <!-- <swiper-slide class="swiper-slide">

    </swiper-slide> -->

    <!-- 分页器 -->
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
    </div>
</template>

<script>
export default {
  props: {
    height: String,
    width: String,
    noDrag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      swiperOption: {


        //显示分页
        pagination: {
          el: '.swiper-pagination'
        },
        preloadImages: false,
        preventClicksPropagation: true,
        //自动轮播
        // autoplay: {
        //   delay: 2000,
        //   //当用户滑动图片后继续自动轮播
        //   disableOnInteraction: false,
        // },
        //开启循环模式
        // loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active',
        },
      }
    }
  }
}
</script>

<style lang="scss" >
.swiperBox {
    .swiper-container {
        display: flex;
        align-items: center;
        height: 100%;

    }
    .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            cursor: pointer;
            margin: 0 0.05rem;
            display: flex;
            align-items: center;
            width: 0.1rem;
            height: 0.1rem;
            border-radius: 50%;
            background: #113e66;
        }
        .my-bullet-active {
            background: #5cb3ff;
        }
    }

}
</style>
