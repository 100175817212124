<template>
<div class="centerRight">

  <div class="b">
    <dv-border-box-12>
      <div class="box">
        <div class="head">
          <span class="blue">公司领域</span>
          <dv-decoration-2 style="width:100%;height:.1rem;"></dv-decoration-2>
        </div>
        <div class="c">
          <div>
            <dv-scroll-ranking-board @click='next' :config="config" style="width:100%;height:100%" />
          </div>
          <!-- <centreRightChart1></centreRightChart1> -->
        </div>
      </div>

    </dv-border-box-12>

  </div>

</div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
  },
  data() {
    return {

    };
  },
  computed: {
    config() {
      return {
        waitTime:3000,
        data: this.dataInfo.territoryCell.map(i=>{
          return {
            name:i.label,
            value:i.value
          }
        })
      }
    }
  },
  mounted() {},
  methods: {
    next(e){
      let name = e.name;
      let value = e.value;
      window.location.href=`https://admin.cjxy.com.cn/community/user/list?territory=${name}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.centerRight {
    display: flex;
    flex-direction: column;
    height: 4rem;
    margin: .2rem 0;
    flex-shrink: 0;
    flex: 1;
    padding-bottom: 1.5rem;

    .b {
        flex: 1;
        .box {
            padding: 0.25rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            .c {
                flex: 1;
                position: relative;
                > div {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .head {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-bottom: 0.1rem;
        .blue {
            color: #5cb3ff;
            font-size: 0.2rem;
            margin-bottom: 0.1rem;
        }
    }


}
</style>
