<template>
<div id="centreLeft1Chart" style="width:100%; height: 100%;"></div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
export default {
  props: {
    dataInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mixins: [echartMixins],
  computed: {
    getAgeCell() {
      return this.dataInfo.ageCell || {}
    },
  },
  watch: {
    getAgeCell: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          let series = this.chart.getOption().series;
          let xAxis = this.chart.getOption().xAxis;
          xAxis[xAxis.length - 1].data = val.map(i => {
            return i.label
          })
          series[series.length - 1].data = val.map(i => {
            return [i.label, i.value]
          })
          this.setOption({
            series,
            xAxis
          })
        })
      }
    },
  },
  mounted() {
    this.draw();
  },
  methods: {
    setOption(data) {
      this.chart.setOption(data);
    },
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreLeft1Chart"));
      //  ----------------------------------------------------------------
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          position: function(pt) {
            return [pt[0], '10%'];
          },
          formatter: params => {
            let str = '';
            params.forEach((item, idx) => {
              str = item.data[0].replace(/(\d+)/g, '$1岁') + '<br/>';

              str += `人数: ${item.data[1]}人 `
            })
            return str
          },
        },
        title: {
          text: "年龄分布(岁)",
          textStyle: {
            fontSize: 14,
            color: "#fff",
            fontWeight: 'normal',
          },
          left: "center",
          top: 15
        },
        darkMode: true,
        color: ['#4992ff'],
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff', //更改坐标轴文字颜色
              fontSize: 10 //更改坐标轴文字大小
            },
            // formatter: function(res) {
            //   return res+"()"
            //   // let data = res.value[2];
            //   // let str = ` ${res.value[1]}元 ${data.reviseType===1?`(${data.postFacePrice} ${data.postPriceRate})`:""}`
            //   // return res.value[1]
            //   }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
          data: [],
        },

        yAxis: {
          type: 'value',
          splitLine: {　　　　
            show: false　　
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff', //更改坐标轴文字颜色
              fontSize: 10 //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
        },
        series: [{
          data: [],
          type: 'bar',
          barWidth: 20
        }]
      });
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
