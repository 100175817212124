<template>
<div id="centreRightChart1" style="width:100%; height: 100%;"></div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
export default {
  data() {
    return {
      chart: null
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreRightChart1"));
      //  ----------------------------------------------------------------
      this.chart.setOption({
        title: {
          text: "公司阶段",
          textStyle: {
            fontSize: 14,
            color: "#fff",
            fontWeight: 'normal',
          },
          left: "center",
          top: 15
        },
        tooltip: {
          trigger: 'axis',
          position: function(pt) {
            return [pt[0], '10%'];
          }
        },
        darkMode: true,
        color: ['#4992ff'],
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff', //更改坐标轴文字颜色
              fontSize: 10 //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
          data: ['A轮', 'B轮', 'C轮', 'D轮', 'E轮以上', 'Pre-IPO', '上市'],
        },

        yAxis: {
          type: 'value',
          splitLine: {　　　　
            show: false　　
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff', //更改坐标轴文字颜色
              fontSize: 10 //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
        },
        series: [{
          data: [120, 200, 150, 80, 70, 110, 130],
          type: 'bar',
          barWidth: 20
        }]
      });
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
