<template>
<div id="centreLeft2Chart" style="width:100%; height: 100%;"></div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
export default {
  props: {
    dataInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mixins: [echartMixins],
  computed: {
    getSignCell() {
      return this.dataInfo.signCell || {}
    },
  },
  watch: {
    getSignCell: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          let series = this.chart.getOption().series;
          series[series.length - 1].data = val.map(i=>{
            return [i.label,i.value]
          })
          this.setOption({
            series
          })
        })
      }
    },
  },
  mounted() {
    this.draw();
  },

  methods: {
    setOption(data) {
      this.chart.setOption(data);
    },
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreLeft2Chart"));
      //  ----------------------------------------------------------------
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          position: function(pt) {
            return [pt[0], '10%'];
          },
          formatter: params => {
            let str = '';
            params.forEach((item, idx) => {
              str = item.data[0] + '<br/>';
              str += `人数: ${item.data[1]}人 `
            })
            return str
          },
        },
        title: {
          text: "星座分布",
          textStyle: {
            fontSize: 14,
            color: "#fff",
            fontWeight: 'normal',
          },
          left: "center",
          top: 15
        },
        darkMode: true,
        color: ['#4992ff'],
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            interval: 0,
            formatter(value) {
              return value.split("").join("\n");
            },
            textStyle: {
              color: '#fff', //更改坐标轴文字颜色
              fontSize: 10 //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },

          data: ['白羊座', '金牛座', '双子座', '巨蟹座', '狮子座', '处女座', '天秤座', '天蝎座', '射手座', '魔羯座', '水瓶座', '双鱼座'],
        },

        yAxis: {
          type: 'value',
          splitLine: {　　　　
            show: false　　
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff', //更改坐标轴文字颜色
              fontSize: 10 //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
        },
        series: [{
          data: [],
          type: 'bar',
          barWidth: 20
        }]
      });
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
