<template>
<div id="centreLeft">

  <div class="b">
    <dv-border-box-12>
      <div class="box">
        <div class="head">
          <span class="blue">学员数据总览</span>
          <dv-decoration-2 style="width:100%;height:.1rem;"></dv-decoration-2>
        </div>
        <div class="scale">
          <div class="l r">
            <div class="a">
              <span></span>
              <h1>人数</h1>
              <div>占比</div>
            </div>
            <div>
              <span>男性学员</span>
              <h1>{{userCount.manCount}}</h1>
              <dv-decoration-9 style="width:.6rem;height:.6rem;">{{toPercent(userCount.manCount)}}%</dv-decoration-9>
            </div>
            <div>
              <span>女性学员</span>
              <h1>{{userCount.womanCount}}</h1>
              <dv-decoration-9 style="width:.6rem;height:.6rem;">{{toPercent(userCount.womanCount)}}%</dv-decoration-9>
            </div>
          </div>
          <div class="r">
            <div class="a">
              <span></span>
              <h1>人数</h1>
              <div>占比</div>
            </div>
            <div>
              <span>创业者</span>
              <h1>{{userCount.cyzCount}}</h1>
              <dv-decoration-9 style="width:.6rem;height:.6rem;">{{toPercent(userCount.cyzCount)}}%</dv-decoration-9>
            </div>
            <div>
              <span>投资人</span>
              <h1>{{userCount.tzrCount}}</h1>
              <dv-decoration-9 style="width:.6rem;height:.6rem;">{{toPercent(userCount.tzrCount)}}%</dv-decoration-9>
            </div>
          </div>
        </div>
        <div class="head">
          <span class="blue">年龄及星座分布</span>
          <dv-decoration-2 style="width:100%;height:.1rem;"></dv-decoration-2>
        </div>


        <mSwiper height='3rem'>
          <swiper-slide class="swiper-slide">
            <centreLeft1Chart :dataInfo='dataInfo'></centreLeft1Chart>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <centreLeft2Chart :dataInfo='dataInfo'></centreLeft2Chart>
          </swiper-slide>
          <!-- <swiper-slide class="swiper-slide">
            <centreLeft1Chart></centreLeft1Chart>
          </swiper-slide> -->
        </mSwiper>
      </div>

    </dv-border-box-12>

  </div>

</div>
</template>

<script>
import centreLeft1Chart from "@/components/echart/centerLeft/centreLeft1Chart";
import centreLeft2Chart from "@/components/echart/centerLeft/centreLeft2Chart";
import mSwiper from "@/components/mSwiper";

export default {
  props: {
    dataInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    centreLeft1Chart,
    centreLeft2Chart,
    mSwiper
  },
  computed: {
    userCount() {
      return this.dataInfo.userCount || {}
    },
    userCountNum() { //总人数数
      return this.userCount.userCount || 0
    },
    toPercent(num) {
      return num => {
        num = num || 0;
        return Number((num / this.userCountNum) * 100).toFixed(2)
      }
    }
  },
  data() {
    return {

    };
  },

  methods: {

  }
};
</script>

<style lang="scss">
#centreLeft {
    display: flex;
    flex-direction: column;
    min-height: 6.125rem;
    height: auto!important;
    .t {
        height: 0.7rem;
        margin-bottom: 0.1rem;
        .border-box-content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .item {
            display: flex;
            align-items: center;
            position: relative;
            justify-content: center;
            flex: 1;
            > div,
            span {
                font-size: 0.225rem;
            }
            div {
                margin-right: 0.325rem;
                flex-shrink: 0;
                color: #5cb3ff;
            }
            span {
                font-size: 0.425rem;
                color: #ff4949;
            }
        }
    }
    .b {
        flex: 1;
        .box {
            padding: 0.25rem;
            height: 100%;
            .scale {
                display: flex;
                margin: 0.3rem 0 0.2rem;
                .l {
                    display: flex;
                    flex-direction: column;
                    margin-right: 0.2rem;
                    flex: 1;
                    // > div {
                    //     display: flex;
                    //     align-items: center;
                    //     height: 0.7rem;
                    //     span {
                    //         font-size: 0.2rem;
                    //     }
                    //     .dv-percent-pond {
                    //         text {
                    //             font-size: 0.2rem;
                    //             font-weight: 400;
                    //         }
                    //     }
                    // }
                }
                .r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    > div {
                        display: flex;
                        align-items: center;
                        height: 0.7rem;
                        position: relative;
                        > span {
                            width: 1rem;
                            font-size: 0.2rem;
                        }
                        > h1 {
                            font-size: 0.3ren;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 0.3rem;
                            width: 1rem;
                        }
                    }
                    .a {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 0.3rem;
                        transform: translateY(-100%);

                        > h1,
                        div {
                            font-size: 0.18rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        div {
                            width: 0.6rem;
                        }
                    }
                }
            }
        }
    }
    .head {
        display: flex;
        flex-direction: column;
        .blue {
            color: #5cb3ff;
            font-size: 0.2rem;
            margin-bottom: 0.1rem;
        }
    }

}
</style>
