<template>
<div id="index">
  <dv-full-screen-container class="bg">
    <dv-loading v-if="loading">Loading...</dv-loading>
    <div v-else class="host-body">
      <div class="d-flex jc-center">
        <dv-decoration-10 style="width:33.3%;height:.0625rem;" />
        <div class="d-flex jc-center">
          <dv-decoration-8 :color="['#568aea', '#000000']" style="width:2.5rem;height:.625rem;" />
          <div class="title">
            <span class="title-text">参加CANPLUS·社群平台</span>
            <dv-decoration-6 class="title-bototm" :reverse="true" :color="['#50e3c2', '#67a1e5']" style="width:3.125rem;height:.1rem;" />
          </div>
          <dv-decoration-8 :reverse="true" :color="['#568aea', '#000000']" style="width:2.5rem;height:.625rem;" />
        </div>
        <dv-decoration-10 style="width:33.3%;height:.0625rem; transform: rotateY(180deg);" />
      </div>

      <!-- 第二行 -->
      <div class="d-flex jc-between px-2">
        <div class="d-flex" style="width: 40%">
          <div class="react-right ml-4" style="width: 6.25rem; text-align: left;background-color: #0f1325;">
            <span class="react-before"></span>

            <!-- <span class="text">数据分析1</span> -->
          </div>
          <div class="react-right ml-3" style="background-color: #0f1325;">
            <!-- <span class="text colorBlue">数据分析2</span> -->
          </div>
        </div>
        <div style="width: 40%" class="d-flex">
          <div class="react-left  mr-3" style="background-color: #0f1325;">
          </div>
          <div class="react-left mr-4" style="width: 6.25rem; background-color: #0f1325; text-align: right;">
            <span class="react-after"></span>
            <span class="text">{{date}}</span>
          </div>
        </div>
      </div>

      <div class="body-box">
        <!-- 第三行数据 -->
        <div class="content-box">
          <div style="width:33%">
            <centerLeft :dataInfo='dataInfo' />
            <centerLeft1 :dataInfo='dataInfo' />
          </div>
          <div style="width:44%">
            <center :dataInfo='dataInfo' :userList='userList'></center>

          </div>
          <div style="width:23%">
            <centerRight :dataInfo='dataInfo'></centerRight>
            <centerRight1 :dataInfo='dataInfo'></centerRight1>
            <!-- <centerRight2></centerRight2> -->
          </div>
        </div>

      </div>
    </div>
  </dv-full-screen-container>
</div>
</template>

<script>
import centerLeft from "./centerLeft";
import centerLeft1 from "./centerLeft1";
import centerRight from "./centerRight";
import centerRight1 from "./centerRight1";
import centerRight2 from "./centerRight2";
import localStorage from '@/assets/js/localStorage'

import center from "./center";
export default {
  data() {
    return {
      loading: true,
      date: "",
      dataInfo: {},
      userList: []
    };
  },
  components: {
    center,
    centerLeft,
    centerLeft1,
    centerRight,
    centerRight1,
    centerRight2,
  },
  mounted() {
    let query = this.$route.query;
    if (query.token) {
      localStorage.setStorageSync('token', query.token);
    }
    this.init()

    this.cancelLoading();
    this.showLeftTime();
  },
  destroyed() {
    clearTimeout(this.timeID);
  },
  methods: {
    init() {
      this.getUserList();
      this.getData();
    },
    getUserList() {
      this.Axios(this.api.userList, {
        type: 'GET',
        data: {
          rows: 99999
        }
      }).then(res => {
        // let arr = res.items.map(i => {
        //   return [i.longitude, i.latitude];
        // });
        let obj = {}
        let arr = [];
        let city = "";
        res.items.forEach(i => {
          let key = `${i.longitude},${i.latitude}`;
          if (obj[key]) {
            obj[key].num++;
          } else {
            obj[key]={
              num:1,
              city:i.city
            }
          }
        })
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            let a = key.split(",");
            a.push(obj[key].num);
            a.push(obj[key].city);
            arr.push(a)
          }
        }
        this.userList = arr;
      })
    },
    getData() {
      this.Axios(this.api.queryData, {
        type: "GET",
      }).then(res => {
        this.dataInfo = res;
      })
    },
    showLeftTime() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      let hours = now.getHours();
      let minutes = now.getMinutes();
      let seconds = now.getSeconds();
      let dd = now.getDay();
      let hour = checkTime(now.getHours()); //获取时
      let minite = checkTime(now.getMinutes()); //获取分
      let second = checkTime(now.getSeconds()); //获取秒
      /****当时、分、秒、小于10时，则添加0****/
      function checkTime(i) {
        if (i < 10) return "0" + i;
        return i;
      }
      let d = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]

      this.date = year + "年" + month + "月" + day + "日 " + d[dd] + `${hour}:${minite}:${second}`;
      //一分刷新一次显示时间
      this.timeID = setTimeout(this.showLeftTime, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
.host-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    .body-box {
        flex: 1;
    }
}
</style>
