<template>
<div id="centreRightChart2"  style="width:100%; height: 100%;"></div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
export default {
  data() {
    return {
      chart: null
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.draw();
  },
  methods: {
    draw() {



      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreRightChart2"));
      let xAxisData = ['A轮', 'B轮', 'C轮', 'D轮', 'E轮以上', 'Pre-IPO', '上市'];
      let endPercent = (6 / xAxisData.length) * 100;
      //  ----------------------------------------------------------------
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          position: function(pt) {
            return [pt[0], '10%'];
          }
        },
        title: {
          text: "行业领域分布",
          textStyle: {
            fontSize: 14,
            color: "#fff",
            fontWeight: 'normal',
          },
          left: "center",
          top: 15
        },

        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            interval: 0,

            textStyle: {
              color: '#fff', //更改坐标轴文字颜色
              fontSize: 10 //更改坐标轴文字大小
            }
          },
          boundaryGap: false,
          data: xAxisData,
        },
        yAxis: {
          type: 'value',
          splitLine: {　　　　
            show: false　　
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff', //更改坐标轴文字颜色
              fontSize: 10 //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
        },
        dataZoom: [{
            type: 'inside',
            start: 0,
            end: endPercent
          },
          {
            start: 0,
            end: endPercent,
            height:15,
          }
        ],
        series: [{
          type: 'line',
          symbol: 'none',
          sampling: 'lttb',
          data: [120, 200, 150, 80, 70, 110, 130],
        }]
      });
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
