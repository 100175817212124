<template>
<div class="bg" ref="myEchart"></div>
</template>
<script>
import "./china.js"; // 引入中国地图数据

export default {
  props: {
    userList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  name: "echarts",
  data() {
    return {
      myChart: null,
      georoam: false,

    };
  },

  watch: {
    userList: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (this.georoam) return;
          let series = this.myChart.getOption().series;
          series[0].data = this.convertData();
          this.setOption({
            series
          })
        })
      }
    },
  },
  mounted() {
    this.georoam = false;
    this.chinaConfigure();
    this.myChart.on('click', function(params) {
      let city = "";
      if (params.name) {
        city = params.name;
      } else {
        let data = params.value;
        city = data[data.length - 1];
      }
      window.location.href = `https://admin.cjxy.com.cn/community/user/list?city=${city}`;
    });
    this.myChart.on('georoam', params => {
      this.georoam = true;
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        this.georoam = false;
      }, 500);
    })
  },
  methods: {
    setOption(data) {
      this.myChart.setOption(data);

    },
    convertData() {
      return this.userList.map(i => {
        return {
          value: i
        }
      });
    },
    chinaConfigure() {
      let self = this;
      this.myChart = this.$echarts.init(this.$refs.myEchart); //这里是为了获得容器所在位置
      window.onresize = this.myChart.resize;

      this.myChart.setOption({
        // 进行相关配置
        // backgroundColor: "rgba(0, 0, 0, 0)", //地图背景色
        geo: {
          // 这个是重点配置区
          map: "china", // 表示中国地图
          label: {
            normal: {
              show: true, // 是否显示对应地名
              textStyle: {
                //字体颜色
                color: "rgba(255,255,255,.5)",
                fontSize: 14
              }
            },
            emphasis: {
              show: true,
              textStyle: {
                color: "#fff"
              }
            }
          },
          roam: true,
          zoom: 1,
          scaleLimit: { //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 0.5, //最小的缩放值
            max: 10, //最大的缩放值
          },
          regions: [{
            name: "南海诸岛",
            itemStyle: {
              // 隐藏地图
              normal: {
                opacity: 0, // 为 0 时不绘制该图形
              }
            },
            label: {
              show: false // 隐藏文字
            }
          }],

          itemStyle: {
            normal: {
              borderWidth: 1, // 地图边框宽度
              borderColor: "rgba(48,159,234,0.30)", // 地图边框颜色
              areaColor: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(48,159,234,0.30)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(48,159,234,0.30) '
                  },
                  {
                    offset: 1,
                    color: 'rgba(48,159,234,0.00)'
                  }
                ]
              )
            },
            emphasis: {
              //选中省份的颜色
              areaColor: "rgba(48,159,234,0.30)",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 0,
              borderWidth: 1,
              shadowColor: "#fff"
            }
          }
        },
        //滑动显示数据
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            return `人数：${params.data.value[2]}`;
          }
        },
        series: [{
            name: "散点",
            type: "effectScatter",
            coordinateSystem: "geo",
            color: "rgba(255,255,0,.5)", //点的颜色
            data: [],
            // symbolSize: 10, //点的大小
            symbolSize: function(parmas) {
              //显示打点的值，即点的大小根据一个方法除数取整
              let num = Math.ceil(parmas[2] / 10);
              return num > 10 ? num : num + 10;
            },
            symbol: "circle", //点的样式
            cursor: "pointer", //鼠标放上去的效果
            label: {
              normal: {
                //默认展示
                show: false
              },
              emphasis: {
                //滑过展示
                show: false
              }
            },

            itemStyle: {
              emphasis: {
                borderColor: "#4c60ff",
                borderWidth: 5
              }
            }
          },
          {
            type: "map",
            map: "china",
            geoIndex: 0,
            aspectScale: 0.75,
            tooltip: {
              show: false
            }
          }
        ]
      });
    }
  },
};
</script>
<style scoped>


</style>
