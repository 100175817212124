<template>
<div class="centerLeft1">

  <div class="b" style="height:100%">
      <dv-border-box-12 style="height:100%">
        <div class="box" >
          <div class="head">
            <span class="blue">估值及年销售额</span>
            <dv-decoration-2 style="width:100%;height:.1rem;"></dv-decoration-2>
          </div>
          <mSwiper width='100%' height='100%'>
            <swiper-slide class="swiper-slide">
              <centreLeft1Chart :dataInfo='dataInfo'></centreLeft1Chart>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <centreLeft2Chart :dataInfo='dataInfo'></centreLeft2Chart>
            </swiper-slide>
            <!-- <swiper-slide class="swiper-slide">
              <centreLeft1Chart></centreLeft1Chart>
            </swiper-slide> -->
          </mSwiper>
        </div>

      </dv-border-box-12>

  </div>

</div>
</template>

<script>
import centreLeft1Chart from "@/components/echart/centerLeft1/centreLeft1Chart";
import centreLeft2Chart from "@/components/echart/centerLeft1/centreLeft2Chart";
import mSwiper from "@/components/mSwiper";

export default {
  props: {
    dataInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    centreLeft1Chart,
    centreLeft2Chart,
    mSwiper
  },
  data() {
    return {

    };
  },
  computed: {

  },
  mounted() {},
  methods: {

  }
};
</script>

<style lang="scss">
.centerLeft1 {
    display: flex;
    flex-direction: column;
    // flex: 1;
    padding: .2rem 0;
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 1.5rem;
    .box {
        padding: 0.25rem;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .head {
        display: flex;
        flex-direction: column;
        .blue {
            color: #5cb3ff;
            font-size: 0.2rem;
            margin-bottom: 0.1rem;
        }
    }

}
</style>
