<template>
<div id="center">
  <div class="up" >
    <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
      <p class="ml-3 colorBlue fw-b">{{item.title}}</p>
      <div @click="next(item)">
        <dv-digital-flop :config="{
          ...item.number,
          style:{
            fontSize:18
          }
          }" style="height:.625rem;" />
      </div>
    </div>
  </div>
  <div class="down">
    <centerChart style="width:100%;height:8rem" :dataInfo='dataInfo' :userList='userList'></centerChart>
  </div>
</div>
</template>

<script>
import centerChart from "@/components/echart/center/centerChart";

export default {
  props: {
    dataInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    userList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: {
    centerChart
  },
  data() {
    return {

    };
  },
  computed: {
    userCount() {
      return this.dataInfo.userCount;
    },
    titleItem() {
      return [{
          title: "学员总数",
          number: {
            number: [this.userCount.userCount],
            content: "{nt}人",
          }
        },
        {
          title: "平均年龄",
          number: {
            number: [this.userCount.avgAge],
            content: "{nt}岁"
          }
        },
        {
          title: "公司总数",
          number: {
            number: [this.userCount.companyCount],
            content: "{nt}"
          }
        },
        {
          title: "总估值",
          number: {
            number: [this.userCount.valCount],
            toFixed: 2,
            content: "{nt}万元"
          }
        },
        {
          title: "创业者数量",
          number: {
            number: [this.userCount.cyzCount],
            content: "{nt}人"
          },
          type:"cyzCount"
        },
        {
          title: "投资者数量",
          number: {
            number: [this.userCount.tzrCount],
            content: "{nt}人"
          },
          type:"tzrCount"
        },
        {
          title: "独角兽数量",
          number: {
            number: [this.userCount.djsCount],
            content: "{nt}人"
          },
          type:"djsCount",
        },
        {
          title: "上市公司数量",
          number: {
            number: [this.userCount.ssCompanyCount],
            content: "{nt}人"
          },
          type:"ssCompanyCount"
        },
      ];
    }
  },
  components: {
    centerChart
    // centerChart1,
    // centerChart2
  },
  methods: {
    next(item) {
      window.location.href = `https://admin.cjxy.com.cn/community/user/list?type=${item.type}`;
    }
  }
};
</script>

<style lang="scss" scoped>
#center {
    display: flex;
    flex-direction: column;
    height: 100%;
    .up {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-left: 0.1rem;
        cursor: pointer;

        .item {
            border-radius: 0.0625rem;
            padding-top: 0.1rem;
            margin-top: 0.1rem;
            margin-right: 0.1rem;
            width: 32%;
            height: 0.875rem;
        }
    }
    .down {

        flex: 1;
        padding: 0.07rem 0.05rem 0;
        width: 100%;
        display: flex;
        height: 3.1875rem;
        justify-content: space-between;
        .bg-color-black {
            border-radius: 0.0625rem;
        }
        .ranking {
            padding: 0.125rem;
            width: 59%;
        }
        .percent {
            width: 40%;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 50%;
                height: 1.5rem;
                span {
                    margin-top: 0.0875rem;
                    display: flex;
                    justify-content: center;
                }
            }
            .water {
                width: 100%;
            }
        }
    }
}
</style>
